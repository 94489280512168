<template>
  <div>
    <div id="apCodeUpload-page">
      <div class="apCodeUpload-page-content">
        <!-- HERO -->
        <div class="main-block position-relative" id="codefilling-block ">          
          <Hero/>
        </div>

        <!-- NYERTESEK -->        
       <!-- <Winners id="nyertesek" v-if="promotionIsAfter" />     -->   

        <!-- NYEREMÉNYEK -->
        <Winnings id="nyeremenyek" />

        <!-- JÁTÉK MENETE -->
        <GamePlay v-if="promotionIsActive || promotionIsBefore"/>
        
        <!-- PALYAZAT BEKULDESE - FORM -->
        <section id="form" v-if="promotionIsActive">
          <div class="competition">
            <div class="container-xl">
              <div class="codefilling__form">
                <CodeFillingForm/>
              </div>
            </div>
          </div>          
        </section>
        <!-- PALYAZAT BEKULDESE - FORM vége-->

        <!-- NYERTESEK -->        
        <Winners id="nyertesek" v-if="promotionIsActive || promotionIsAfter"/>        

        <!-- CONTACT -->        
        <Contact v-if="promotionIsActive" />        

        <!-- NEWSLETTER -->
        <!--<Newsletter />-->

      </div>            
    </div>    
  </div>

</template>


<script>
import Hero from '@/components/Hero.vue'
import CodeFillingForm from "@/components/CodeFilling-form.vue";
import Winnings from "@/components/Winnings.vue";
import GamePlay from '@/components/GamePlay.vue'
import Winners from '@/components/Winners.vue';
import Contact from "@/components/Contact";
import $ from "jquery";
//import Newsletter from "@/components/Newsletter.vue"

export default {
  data() {
    return {
      alreadyRegistered: false,
      formExpired: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      backgroundChanger: false,
    };
  },
  mounted() {
    this.$store.dispatch("getSettings");
    this.$store.dispatch("getFileUrl");

     if (this.$route.params.scrollTo){            
            setTimeout(() => {
                var element = document.getElementById(this.$route.params.scrollTo)
                element.scrollIntoView()       
            }, 1000);
            
        }
  },
  methods: {
    setGTM(item){
      this.GTtrackGA4({
        'event' : 'event',
        'category': 'jatekleiras',
        'action': 'gomb_kattintas',
        'label': item,
        'clicked_text':item,
        'action_type': 'gomb_kattintas'
      })
    },
  },
  components: {
    Hero,     
    Contact,
    CodeFillingForm,    
    Winnings,    
    Winners,  
    GamePlay,
    //Newsletter
  },
  created() {
    $('[data-toggle="tooltip"]').tooltip();

  },

};
</script>
